
import { AxiosPromise } from "axios"
import request from "../request"
export default {
    captchaImage() {
        return request({
            url: '/captchaImage',
            method: 'get',
            headers: { isLogin: '1' }
        })

    },
    login(data: {
        code: string,
        username: string,
        password: string,
        uuid: string

    }): Promise<loginApi.loginRes> {
        return request({
            url: '/login',
            method: 'post',
            data: data,
            headers: { isLogin: '1' }
        })

    },
    logout(): Promise<loginApi.loginRes> {
        return request({
            url: '/logout',
            method: 'post'
        })
    }


}


