<template>
  <router-view />
</template>


<style lang="scss">
@import url("./assets/css/textColor.css");
@import url("./assets/css/width.css");
@import url("./assets/css/height.css");
@import url("./assets/css/line-height.css");
@import url("./assets/css/bg.css");
@import url("./assets/css/bottom.css");
@import url("./assets/css/top.css");
@import url("./assets/css/left.css");
@import url("./assets/css/right.css");
@import url("./assets/css/flex.css");
@import url("./assets/css/fontSize.css");
@import url("./assets/css/fontWeight.css");
@import url("./assets/css/mb.css");
@import url("./assets/css/mt.css");
@import url("./assets/css/mr.css");
@import url("./assets/css/ml.css");
@import url("./assets/css/pl.css");
@import url("./assets/css/pr.css");
@import url("./assets/css/pt.css");
@import url("./assets/css/pb.css");
@import url("./assets/css/radius.css");
@import url("./assets/css/tracking.css");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
 
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

