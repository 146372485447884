import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/login/index.vue'
import Error404 from '../views/error404/index.vue'
const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/',
    name: '',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login
  },
  
  {
    path: '/studySkill',
    component: () => import('../views/studySkill/index.vue')
  },
  {
    path: '/threeExam',
    component: () => import('../views/threeExam/index.vue')
  },
  {
    path: '/threeExamAnaly',
    component: () => import('../views/threeExamAnaly/index.vue')
  },
  {
    path: '/threeStudyExam',
    component: () => import('../views/threeStudyExam/index.vue')
  },
  {
    path: '/examInstructions',
    component: () => import('../views/examInstructions/index.vue')
  },
  {
    path: '/driverExam',
    component: () => import('../views/driverExam/index.vue')
  },
  {
    path: '/driverExamAnaly',
    component: () => import('../views/driverExamAnaly/index.vue')
  },
  {
    path: '/markLine',
    component: () => import('../views/markLine/index.vue')
  },
  {
    path: '/reviewDriverError',
    component: () => import('../views/reviewDriverError/index.vue')
  },
  {
    path: '/reviewThreeError',
    component: () => import('../views/reviewThreeError/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    children: [{
      path: 'sysconfig',
      component: () => import('../views/home/sysconfig/index.vue')
    }, {
      path: 'practiseSelect',
      component: () => import('../views/home/practiseSelect/index.vue')
    },
    {
      path: 'markLine',
      component: () => import('../views/home/markLine/index.vue')
    },
    {
      path: 'selectDriveExamSubject',
      component: () => import('../views/home/selectDriveExamSubject/index.vue')
    },{
      path: 'threeExam',
      component: () => import('../views/home/threeExam/index.vue')
    },
    {
      path: 'scoreList',
      component: () => import('../views/home/scoreList/index.vue')
    }, {
      path: 'logOut',
      component: () => import('../views/home/logOut/index.vue')
    }],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue')
  },
  {
    path:'/:pathMatch(.*)',
    component:Error404
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
