
import api from "@/api";
import router from "@/router";
import Cookies from "js-cookie";
import { defineComponent, reactive, toRefs } from "vue";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import store from "@/store";
export default defineComponent({
  setup() {
    const state = reactive({
      codeUrl: "",
      isSavePassword: false,
      loginForm: {
        uuid: "",
        code: "",
        rememberMe: false,
        username: "",
        password: "",
      },
    });
    return {
      ...toRefs(state),
    };
  },
  mounted() {
    this.getCookie();
    this.getCaptchaImage();
  },
  methods: {
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        uuid: "",
        code: "",
        username: username === undefined ? this.loginForm.username : username,
        password:
          password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    loginByPassword() {
      console.log("登录了");
      api.login.login(this.loginForm).then((res) => {
        window.localStorage.setItem("token", res.data.token);
        router.push("/home/sysconfig");
        store.dispatch("AsyncDriver4WrongList");
        store.dispatch("AsyncDriver1WrongList");
        store.dispatch("AsyncThreeWrongList")
        console.log(res.data.token);
      });
    },
    getCaptchaImage() {
      api.login.captchaImage().then((res) => {
        // console.log(res)
        this.codeUrl = "data:image/gif;base64," + res.data.img;
        this.loginForm.uuid = res.data.uuid;
      });
    },
  },
});
