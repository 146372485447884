import request from "../request"
import { threeApi } from "./type"
export default {
    //查询三力测试列表
    threeForceList(): Promise<threeApi.threeForceList> {
        return request({
            url: "/three/force/list",
            method: 'get'
        })
    },
    //获取三力测试模拟考题
    threeForceSelectTestQuestionInfoList(): Promise<threeApi.threeForceList> {
        return request({
            url: "/three/force/selectTestQuestionInfoList",
            method: 'get'
        })
    },
    //(三力测试)获取题库详细信息批量取题
    threeForceGetThreeForceListIds(params: {
        ids: string
    }): Promise<threeApi.threeForceList> {
        return request({
            url: "/three/force/getThreeForceListIds",
            method: 'get',
            params

        })
    }

}
