
import login from './login/login'
import open from './open/open'
import question from './question/question'
import three from './three/three'
import traffic from './traffic/traffic'
import score from './score/score'
const api  = {
    login,
    open,
    question,
    traffic,
    three,
    score

    


}
export default api