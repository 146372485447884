import request from "../request"
import { openApi } from "./type"
export default {
    questionInfoSelectFreeQuestionInfo(params: {
        subject: number
    }):Promise<openApi.selectFreeQuestionInfoRes> {
        return request({
            params,
            url: "/open-api/question/info/selectFreeQuestionInfo",
            method: 'get'

        })
    },
    questionInfoSelectTestK14QuestionInfoList(params:{
        subject:number,
        gs:string
    }):Promise<openApi.selectFreeQuestionInfoRes>{
        return request({
            params,
            url: "/open-api/question/info/selectTestK14QuestionInfoList",
            method: 'get'
        })
    }
}
    